
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































@import '~spinthatshit/src/loaders';

/* stylelint-disable order/order */
$loader-color: $c-white;
$loader-size: 6px;
$loader-gap: 8px;
/* stylelint-enable order/order */

.ui-loading-global {
  @include get-all-space;

  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-blue-30;

  .dots-outer {
    width: 7rem;
    height: 4.4rem;
    padding: 1.8rem 3.1rem;
    background-color: $c-blue;
    border-radius: 6px;
    box-shadow: 0 16px 30px rgba($c-black, 0.2);
  }

  .dots {
    @include loader10;
  }
}
