
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































































































































.header {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 8rem;

  @include mq(m) {
    height: 12rem;
  }
}

.header-inner {
  display: flex;
  align-items: center;
}

.header__logo__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.4rem;
  padding: 2rem 0;
  color: $c-white;

  @include mq(m) {
    width: 21.7rem;
    padding: 2.5rem 0;
  }
}

.header__nav {
  margin-left: auto;
}

.header__burger {
  position: relative;
  width: 3rem;
  margin: 0 0 0 auto;
  padding: 0;
  color: currentColor;
  background: transparent;
  border-width: 0;
  cursor: pointer;

  @include mq(m) {
    width: 4rem;
    margin: 0;
  }
}

.header__burger__line {
  width: 100%;
  height: 0.2rem;
  margin-bottom: 0.6rem;
  background: currentColor;
  pointer-events: none;
  transform-origin: 0 0;
  transition: transform $easing $transition-duration;

  &:last-child {
    margin-bottom: 0;
    transform-origin: 0 0;
    transform: scaleX(0.5);
  }
}

// BURGER MENU
.header.burger-menu-opened {
  .header__burger {
    z-index: 10;

    .header__burger__line {
      width: 4rem;

      &:nth-child(1) {
        transform: rotate(21deg) scaleX(1.1);
      }

      &:nth-child(2) {
        transform: scaleX(0);
      }

      &:nth-child(3) {
        transform: rotate(-21deg) scaleX(1.1);
      }
    }
  }
}
