
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































.newsletter__title {
  @include fluid(
    font-size,
    (
      s: 16px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 28px,
      xl: 19px,
    )
  );
}

.newsletter__form {
  position: relative;
  margin-bottom: 4rem;
}

.newsletter__submit {
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0;
  padding: 0;
  color: currentColor;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: inherit;
  text-transform: uppercase;
  background: transparent;
  border-width: 0;
  cursor: pointer;
  transform: translateY(-50%);

  svg {
    margin-top: 1rem;
  }
}

.newsletter__socials {
  display: flex;
  align-items: center;

  span {
    margin-right: 1.5rem;
    color: $c-gray;
    font-size: 1.3rem;
  }
}

.request-message {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 800;
}
