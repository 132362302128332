
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.duo {
  position: relative;
}

.duo-inner {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;

  @include mq(m) {
    display: flex;
    align-items: center;
  }
}

.duo__header {
  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  @include mq(m) {
    width: col(8, 24);
    margin-left: col(1, 24);
    padding: 0;
  }

  @include mq(xl) {
    width: col(7, 24);
    margin-left: col(2, 24);
  }
}

.duo__content {
  @include mq($until: m) {
    margin-top: 1.5rem;
  }

  @include mq(m) {
    width: col(14, 24);
    margin-left: col(1, 24);
  }

  @include mq(xl) {
    width: col(13, 24);
    margin-left: col(2, 24);
  }
}

.duo__content__picture-outer {
  @include aspect-ratio(1, 1);
}

.duo__content__picture {
  @include image-fit($fit: contain);
}

.duo__content__frame {
  @include aspect-ratio(1, 1);
}

.duo__content__frame iframe {
  @include get-all-space;

  border: 0;
}
