
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































.flexible {
  > div {
    &:first-child,
    &:first-child > div {
      margin-top: 0;

      ::v-deep .pattern {
        display: none;
      }
    }

    &:last-child,
    &:last-child > div {
      margin-bottom: 0;
    }
  }

  .cpt-textDuo + .cpt-textDuo {
    ::v-deep .pattern-top {
      display: none;
    }
    ::v-deep .pattern-bottom {
      display: inline-flex;
    }
  }
}
