
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.duo__header {
  position: relative;

  @include mq(m) {
    width: col(8, 24);
  }
}

.duo__header__headline {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 18px,
      xl: 19px,
    )
  );

  margin-bottom: 0.5rem;
  color: $c-blue;

  @include mq(m) {
    margin-bottom: $spacing * 0.75;
  }
}

.duo__header__title {
  @extend %ff-alt;
}

.duo__header__text {
  @include fluid(
    font-size,
    (
      s: 15px,
      xl: 20px,
    )
  );

  margin-top: $spacing * 1.5;
  line-height: 2;
}

.duo__header__links {
  @extend %list-nostyle;
}

.duo__header__link {
  @extend %ff-alt;

  font-size: 2rem;
  border-bottom: 1px solid $c-gray-lighter;

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $c-black;
    text-decoration: none;

    .icon-wrapper {
      position: relative;
      display: flex;

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        display: inline-flex;
        width: 2.4rem;
        height: 2.4rem;
        background-color: $c-yellow;
        border-radius: 999em;
        transform: scale(0);
        transition: transform 0.35s ease-in-out;
      }
    }

    .icon {
      transition: transform 0.35s ease-in-out;
    }

    &:hover {
      .icon-wrapper::after {
        transform: scale(1);
      }

      .icon {
        transform: scale(0.7);
      }
    }
  }
}

.pattern-top {
  top: -14rem;
  left: 0;
}

.pattern-bottom {
  position: relative;
  margin-top: 4rem;
}
