.cookiebar {
  z-index: 2;
  overflow-y: auto;
  max-width: 40rem;
  color: $c-black;
  background-color: $c-yellow;
  border-radius: 20px;
}

.cookiebar__header {
  padding-top: 3rem;
}

.cookiebar__header__title {
  @extend %ff-alt;

  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.8rem;
}

.cookiebar__header__text {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.7rem;
}

.cookiebar__header__actions {
  align-items: center;
  padding-top: 1rem;
  border-color: $c-gray-light;
}

.cookiebar__header__actions__details {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-decoration: underline;
}

.cookiebar__header__actions__validate {
  width: 3.6rem;
  height: 3.6rem;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.8rem;
  background: $c-white;
  border-width: 0;
  border-radius: 50%;
}
