
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































.duo {
  position: relative;
}

.duo-inner {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;

  @include mq(m) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.duo__header {
  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  @include mq(m) {
    width: col(8, 24);
    margin-right: col(1, 24);
    padding: 0;
  }

  @include mq(xl) {
    width: col(7, 24);
    margin-right: col(2, 24);
  }
}

.duo__content {
  @include mq($until: m) {
    margin-top: 1.5rem;
  }

  @include mq(m) {
    width: col(14, 24);
    margin-right: col(1, 24);
  }

  @include mq(xl) {
    width: col(13, 24);
    margin-right: col(2, 24);
  }
}

.duo__content__snippet {
  @include aspect-ratio(1, 0.75);

  background-color: $c-white;
}

::v-deep .stmlk_epic-widget {
  position: absolute;
  inset: 6rem 3rem;
  border: 1px solid $c-black-10;
  border-radius: 2rem;
}

.widgetDuo {
  ::v-deep {
    .duo__content {
      @include mq($until: m) {
        margin-top: 0;
      }
    }
  }
}
