
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































































.collapse {
  position: relative;
  padding: 0 0 6rem;

  & + & {
    padding-top: 5rem;
    border-top: 1px solid $c-black-10;
  }
}

.collapse__header {
  position: relative;
  padding-right: 10rem;
  cursor: pointer;
}

.collapse__header__label {
  color: $c-black;
  line-height: 1.5;
}

.collapse__header__icon {
  @include center-y;

  right: 20px;
  width: 5rem;
  height: 5rem;
  padding: 1.5rem;
  border: 1px solid $c-yellow;
  border-radius: 999em;
  transition: background-color 0.35s $ease-out-quad;

  .collapse__header:hover & {
    background-color: $c-yellow;
  }
}

.collapse-inner {
  overflow: hidden;
}

.collapse__text {
  margin: 3.6rem 0 2rem;

  ::v-deep(p) {
    margin-bottom: $spacing;
  }

  ::v-deep(a) {
    color: $c-blue;
    text-decoration: underline;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 1.4;
  }
}
