
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































































































































.keypoints {
  display: block;
  background: $c-yellow;
}

.keypoints-inner {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
}

.keypoints__header__headline {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );

  margin-bottom: $spacing * 0.75;
  color: $c-white;
}

.keypoints__header__title {
  @extend %ff-alt;

  color: $c-black;
}

.keypoints__slider {
  display: flex;
  overflow: hidden;

  @include mq($until: m) {
    flex-direction: column;
    width: col(22, 24);
    margin-top: $spacing * 1.5;
    margin-left: col(2, 24);
  }

  @include mq(m) {
    flex-direction: row;
    align-items: flex-start;
    width: col(23, 24);
    margin-left: col(1, 24);
  }

  @include mq(xl) {
    width: col(22, 24);
    margin-left: col(2, 24);
  }
}

.keypoints__nav {
  @include fluid(
    padding-right,
    (
      m: 60px,
      xl: 120px,
    )
  );

  position: relative;
  z-index: 1;

  @include mq($until: m) {
    order: 1;
    margin-top: $spacing * 1.5;
  }

  @include mq(m) {
    display: flex;
  }
}

.keypoints__list {
  @extend %list-nostyle;

  display: flex;
}

.keypoints__item {
  @include fluid(
    padding-right,
    (
      xxs: 50px,
      m: 60px,
    )
  );

  flex: 1 0 auto;
  transition: 0.3s $ease;
}

.keypoints__item-inner {
  width: 26rem;
  height: 100%;
  border-bottom: 2px solid $c-black;

  @include mq(m) {
    width: 42rem;
  }
}

.keypoints__item__title {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 36px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 30px,
      xl: 40px,
    )
  );

  margin-bottom: 0.5rem;
  color: $c-white;
}

.keypoints__item__subtitle {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 18px,
      xl: 30px,
    )
  );

  padding-bottom: 3rem;
  font-weight: 400;
}
