
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































.services {
  @extend %list-nostyle;
}

.service {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2rem;
  border-bottom: 1px solid $c-gray-lighter;
}

.service__subtitle {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 18px,
      xl: 20px,
    )
  );

  max-width: 19rem;

  @include mq(s) {
    max-width: 34rem;
  }
}

.service__picture {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 5rem;
  margin-right: $spacing;

  img {
    @include mq($until: m) {
      width: 100%;
    }
  }

  @include mq(m) {
    flex: 0 0 10rem;
    padding: 2.5rem;
  }
}

.service__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;
  transition: transform cubic-bezier(0.25, 1, 0.5, 1) 0.4s;

  .service:hover &,
  .service:focus & {
    transform: translateX(1rem);
  }
}

.service__link {
  @include get-all-space;

  z-index: 2;
}
