
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













.quote__content {
  display: flex;
  align-items: flex-start;
}

.quote__icon {
  width: 2.5rem;

  @include mq(m) {
    width: 5rem;
    margin-right: 3rem;
  }
}

.quote__text {
  @include fluid(
    font-size,
    (
      s: 30px,
      xl: 20px,
    )
  );

  line-height: 1.3;

  @include mq($until: m) {
    margin-top: 2rem;
  }
}

.quote__author {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );

  color: $c-blue;
  line-height: 1.5;
  text-align: right;
}
