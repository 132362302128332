/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-black-50: rgba(0, 0, 0, 0.5);
$c-black-25: rgba(0, 0, 0, 0.25);
$c-black-10: rgba(0, 0, 0, 0.1);
$c-black-05: rgba(0, 0, 0, 0.05);
$c-black-02: rgba(0, 0, 0, 0.02);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: rgba(0, 0, 0, 0.5);
$c-gray-light: rgba(0, 0, 0, 0.25);
$c-gray-lighter: rgba(0, 0, 0, 0.1);
$c-gray-lightest: rgba(0, 0, 0, 0.05);
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
// $c-red: #f00;
// $c-green: #17b603;
$c-lemon: #f8e71c;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-yellow: #ffc330;
$c-blue: #6478ff;
$c-blue-30: rgba($c-blue, 0.3);
$c-red: #ff3200;
$c-green: #00c983;
$c-footer: #fafafa;

/*
* Dimensions
*/
$content-width: 1680px;
$header-height: 60px;
$header-height-large: 100px;
$header-breakpoint: xl;

/*
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'Inter', sans-serif;
$ff-alt: 'CirculatStd', sans-serif;
$body-text: 1.5rem;
$body-text-m: 1.8rem;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$spacing: 2rem;
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/**
* ANIMATION
*/
$easing: cubic-bezier(0.25, 1, 0.5, 1);
$transition-duration: 400ms;
