
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































.dashboard-widget,
[class*='dashboard-widget--'] {
  position: relative;

  ::v-deep > div {
    @include get-all-space;
  }
}
