
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































.logos-gallery {
  display: block;
}

.logos-gallery-inner {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
}

.logos-gallery__header__headline {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );

  margin-bottom: $spacing * 0.75;
  color: $c-blue;
}

.logos-gallery__header__title {
  @extend %ff-alt;

  color: $c-black;
}

.logos-gallery__slider {
  overflow: hidden;

  @include mq($until: m) {
    width: col(22, 24);
    margin-top: $spacing * 1.5;
    margin-left: col(2, 24);
  }

  @include mq(m) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: col(23, 24);
    margin-left: col(1, 24);
  }

  @include mq(xl) {
    width: col(22, 24);
    margin-left: col(2, 24);
  }
}

.logos-gallery__list {
  @extend %list-nostyle;

  display: flex;
}

.logos-gallery__item {
  @include fluid(
    padding-right,
    (
      xxs: 50px,
      m: 60px,
    )
  );

  display: flex;
  align-items: center;
  flex: 1 0 auto;
  transition: 0.3s $ease;
}

.logos-gallery__item-inner {
  position: relative;

  img {
    max-height: 14rem;
  }
}

.logos-gallery__link {
  @include get-all-space;

  z-index: 2;
}

.logos-gallery__nav {
  @include fluid(
    padding-right,
    (
      m: 60px,
      xl: 120px,
    )
  );

  position: relative;
  z-index: 1;
  display: none;

  @include mq(m) {
    display: flex;
  }
}
