
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.form-input,
[class*='form-input--'] {
  position: relative;
  padding: 1rem 0;
}

.form-input__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  color: $c-gray;
  font-size: 1.8rem;
  transition: top $ease 0.1s, font-size $ease 0.1s;
  pointer-events: none;
}

.form-input__field {
  @extend %fw-bold;

  width: 100%;
  padding-left: 0;
  background: none;
  border: 0;
  border-bottom: 0.3rem solid $c-black;

  &:focus,
  &.is-filled {
    + .form-input__label {
      top: -$spacing;
      color: $c-gray;
      font-size: 1rem;
      pointer-events: none;
    }
  }
}
