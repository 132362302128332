
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.card {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: $spacing * 2;

  @include mq(m) {
    flex-direction: column;
    width: 35rem;
    margin-bottom: 0;
  }
}

.card__picture-outer {
  @include aspect-ratio(1, 1);

  flex: 0 0 12rem;
  overflow: hidden;
  width: 12rem;
  margin-right: 1.5rem;
  border-radius: 2rem;

  @include mq($until: m) {
    height: 12rem;
  }

  @include mq(m) {
    @include aspect-ratio(35, 23);

    flex: 0 0 auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 2.5rem;
    border-radius: 3rem;
  }
}

.card__picture {
  @include image-fit;

  transition: transform 0.2s;

  .card:hover & {
    transform: scale(1.04);
  }
}

.card__content {
  @extend %ff-alt;

  display: flex;
  flex-direction: column;

  @include mq(m) {
    min-height: 15rem;
  }
}

.card__category {
  @include fluid(
    font-size,
    (
      s: 9px,
      l: 10px,
      xl: 11px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 11px,
      l: 13px,
      xl: 14px,
    )
  );

  margin-bottom: $spacing / 4;
  color: $c-blue;
  text-transform: uppercase;

  @include mq(m) {
    letter-spacing: 5%;
  }
}

.card__title {
  @include fluid(
    font-size,
    (
      s: 15px,
      xl: 20px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 20px,
      xl: 30px,
    )
  );

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__date {
  @include fluid(
    font-size,
    (
      s: 11px,
      xl: 12px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 11px,
      xl: 30px,
    )
  );

  margin-top: auto;
  color: $c-gray;
  letter-spacing: 0.1em;

  @include mq($until: m) {
    margin-top: 2.5rem;
  }
}

.card__link {
  @include get-all-space;

  z-index: 2;
}
