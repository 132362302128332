
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















.mobility-cta {
  display: flex;
  align-items: center;
  padding-right: 5rem; /* Todo */
  text-decoration: none;
  white-space: nowrap;
}

.mobility-cta__logo {
  max-width: 4rem;
  margin-right: 2rem;

  @include mq(m) {
    max-width: 6rem;
    margin-right: 3rem;
  }
}

.mobility-cta__content {
  @extend %ff-alt;
}

.mobility-cta__title {
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 20px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 30px,
      xl: 28px,
    )
  );

  color: $c-black;

  .mobility-cta:hover & {
    color: $c-blue;
  }
}

.mobility-cta__subtitle {
  @extend %ff-default;
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 18px,
      xl: 24px,
    )
  );

  color: $c-blue;
}
