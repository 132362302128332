
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































.ui-side-panel-outer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: $c-blue-30;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s $ease-in-quad 0.4s;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.35s $ease-out-quad;
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 72rem;
  height: 100vh;
}

.panel-inner {
  overflow: auto;
  height: 100%;
}

.panel__component {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 35px, xxl: 120px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 35px, xxl: 120px));

  min-width: 100%;
  min-height: 100vh;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: $c-white;
}

.close {
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background: $c-white;

  svg {
    fill: $c-blue;
  }

  // @include mq(xl) {
  //   top: 2rem;
  //   left: 0;
  //   border: 1px $c-gray solid;
  //   border-radius: 50%;
  //   transform: translateX(-50%);

  //   svg {
  //     margin-right: 3px;
  //   }
  // }
}

// Transitions
.panel-enter-active {
  transition: transform 0.25s ease-out;
}

.panel-leave-active {
  transition: transform 0.15s ease-in;
}

.panel-leave,
.panel-enter-to {
  transform: translateX(0);
}

.panel-enter,
.panel-leave-to {
  transform: translateX(100%);
}
