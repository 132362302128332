
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























// REVIEW: to check after chrome (with footer) is complete
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-view {
  flex-grow: 1;
  min-height: 100vh;
}
