
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















.duo {
  position: relative;
}

.duo-inner {
  @include mq(m) {
    display: flex;
  }
}

.duo__header {
  @include mq(m) {
    width: col(7, 20);
  }
}

.duo__content {
  @include mq($until: m) {
    margin-top: 1.5rem;
  }

  @include mq(m) {
    width: col(11, 20);
    margin-left: col(2, 20);
    padding-top: $spacing * 1.5;
  }
}

.duo__content__bullets {
  @extend %list-nostyle;

  counter-reset: bullets-counter;

  li {
    @include fluid(
      font-size,
      (
        s: 20px,
        xl: 30px,
      )
    );

    display: flex;
    align-items: center;
    counter-increment: bullets-counter;

    span {
      @extend %ff-alt;

      line-height: 1.5;
    }

    &::before {
      @extend %fw-light;

      content: counter(bullets-counter);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      width: 4rem;
      height: 4rem;
      margin-right: $spacing;
      border: 2px solid $c-yellow;
      border-radius: 50%;

      @include mq(l) {
        width: 5.7rem;
        height: 5.7rem;
        margin-right: $spacing * 1.5;
      }
    }
  }
}
