
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.anchors {
  position: relative;
}

.anchors__content {
  width: 100%;
  padding: 2rem 3rem;
  border: 1px solid $c-gray-light;
  border-radius: 20px;

  @include mq(m) {
    display: flex;
    align-items: flex-start;
    padding: 4rem 7.5rem;
  }
}

.anchors__title {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.anchors__icon {
  width: 2rem;
  margin-right: 2rem;

  @include mq(m) {
    width: 3rem;
  }
}

.anchors__lists {
  display: flex;
  flex-direction: column;

  @include mq($until: m) {
    margin-top: $spacing * 0.5;
  }

  @include mq(m) {
    margin-left: col(2);
  }

  @include mq(l) {
    flex-direction: row;
    width: 100%;
  }
}

.anchors__list {
  margin: 0;
  padding-top: 0.7rem;
  font-size: 1.5rem;
  line-height: 1.6;
  text-decoration: none;

  li {
    position: relative;
    padding-bottom: 1.5rem;
    line-height: 1.4em;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.7em;
      left: -22px;
      width: 5px;
      height: 5px;
      background: $c-blue;
      border-radius: 50%;
    }
  }

  a {
    color: $c-black;
  }

  &:last-child {
    @include mq(l) {
      margin-left: col(1);
    }
  }

  @include mq($until: m) {
    margin-top: $spacing * 0.5;
    margin-left: -1rem;
  }

  @include mq(l) {
    width: 50%;
  }
}

.anchors__list + .anchors__list {
  @include mq($until: l) {
    margin-top: 0;
    padding-top: 0;
  }
}
