.wysiwyg,
[class*='wysiwyg--'] {
  h1,
  .h1,
  h2,
  .h2 {
    @include fluid(
      margin-bottom,
      (
        xxs: 30px,
        m: 50px,
      )
    );
  }

  h3,
  .h3,
  h4,
  .h4 {
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        m: 30px,
      )
    );
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    @include fluid(
      font-size,
      (
        s: 15px,
        l: 16px,
        xl: 18px,
      )
    );
    @include fluid(
      line-height,
      (
        s: 22px,
        l: 26px,
        xl: 30px,
      )
    );

    position: relative;
    padding-bottom: 1.5rem;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.7em;
      left: -22px;
      width: 5px;
      height: 5px;
      background: $c-yellow;
      border-radius: 50%;
    }
  }

  ol li {
    @include fluid(
      font-size,
      (
        s: 15px,
        l: 16px,
        xl: 18px,
      )
    );
    @include fluid(
      line-height,
      (
        s: 22px,
        l: 26px,
        xl: 30px,
      )
    );

    position: relative;
    padding-bottom: 1.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-black;
    }
  }

  a {
    color: $c-blue;
    text-decoration: none;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  iframe {
    @include mq($until: m) {
      width: 100%;
    }
  }
}
