
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































.nav {
  @extend %list-nostyle;

  display: none;

  @include mq(m) {
    display: flex;
  }
}

.nav__item {
  @extend %ff-alt;

  margin-right: 6rem;
  font-size: 2rem;
  line-height: 1.2;

  &:last-child {
    ::v-deep .nav-children {
      right: 0;
      left: auto;
      transform: translateX(0);
    }

    ::v-deep .nav-children::before {
      right: 2rem;
      left: auto;
      transform: translateX(0);
    }
  }
}

.nav__item__link {
  position: relative;
  color: $c-black;
  text-decoration: none;
  cursor: pointer;

  > span {
    padding: 3rem 1rem;
  }

  &:hover,
  &.is-open {
    color: $c-blue;
  }
}
