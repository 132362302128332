
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































































































.share__title {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-blue;
  font-size: 1.5rem;
  line-height: 2;
}

.share__items {
  @extend %list-nostyle;

  display: flex;
  line-height: 1;
}

.share__item {
  position: relative;
  padding-right: 1.5rem;

  svg {
    pointer-events: none;
  }

  &:hover {
    color: $c-blue;

    svg {
      fill: $c-blue;
    }
  }
}
