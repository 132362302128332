
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































































































.related-news {
  display: block;

  ::v-deep [class*='action--'][class*='--slider'] {
    border-color: $c-yellow;

    &:hover {
      background: $c-yellow;
    }
  }
}

.related-news-inner {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;

  @include mq($until: m) {
    padding-top: 0;
  }
}

.related-news__slider {
  overflow: hidden;

  @include mq($until: m) {
    width: col(20, 24);
    margin-top: $spacing * 1.5;
    margin-right: col(2, 24);
    margin-left: col(2, 24);
  }

  @include mq(m) {
    display: flex;
    flex-direction: row;
    width: col(23, 24);
    margin-left: col(1, 24);
  }

  @include mq(xl) {
    width: col(22, 24);
    margin-left: col(2, 24);
  }
}

.related-news__content {
  @include mq(m) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 col(7, 22);
    width: 100%;
  }
}

.related-news__title {
  @extend %ff-alt;

  color: $c-black;

  @include mq(m) {
    margin-top: $spacing * 2;
  }
}

.related-news__nav {
  @include fluid(
    padding-right,
    (
      m: 60px,
      xl: 120px,
    )
  );

  position: relative;
  z-index: 1;
  display: none;

  @include mq(m) {
    display: flex;
  }
}

.related-news__link {
  @extend %ff-alt;
  @include custom-underline;

  display: inline-flex;
  margin-top: auto;
  color: $c-black;
  font-size: 1.5rem;
  line-height: 4rem;
  text-decoration: none;
}

.related-news__list {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;

  @include mq($until: m) {
    height: auto !important; /* stylelint-disable-line declaration-no-important */
  }

  @include mq(m) {
    flex-direction: row;
  }
}

.related-news__card {
  flex: 1 0 auto;
  transition: 0.3s $ease;

  @include mq(m) {
    padding-right: 6rem;
  }
}
