
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.nav-children {
  position: absolute;
  z-index: 5;
  top: calc(100% + 3rem);
  left: 50%;
  display: none;
  padding: $spacing * 2; /* 5rem */
  line-height: 2rem;
  background: $c-white;
  border-radius: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%);

  &::before {
    content: '';
    position: absolute;
    z-index: 8;
    top: -1.5rem;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-width: 0 33px 44px;
    border-style: solid;
    border-color: transparent transparent $c-white transparent;
    transform: translateX(-50%);
  }

  .is-open & {
    display: block;
  }
}

.nav-children__list {
  @extend %list-nostyle;
}

.nav-children__item {
  margin-top: $spacing;

  &:first-child {
    margin-top: 0;
  }
}

.nav-children__item__link {
  display: flex;
  align-items: center;
  min-width: 25rem;
  text-decoration: none;

  ::v-deep .mobility-cta {
    .mobility-cta__logo {
      max-width: 4rem;
      margin-right: $spacing;
      margin-bottom: 1rem;
    }

    .mobility-cta__title {
      @include mq(m) {
        font-size: 1.5rem;
        line-height: 1.9rem;
      }
    }

    .mobility-cta__subtitle {
      @include mq(m) {
        margin-bottom: 1rem;
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
  }
}
