
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































.footer {
  position: relative;
  width: 100%;
  background-color: $c-footer;
}

// MAIN
.footer__main {
  display: flex;
  align-items: flex-start;

  > * {
    @include mq(m) {
      width: col(5, 20);
    }
  }
}

.footer__logo__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.5rem;

  @include mq(m) {
    width: 16.9rem;
  }
}

// NAV
.footer__nav {
  @extend %list-nostyle;

  @include mq($until: m) {
    display: none;
  }
}

.footer__nav__link {
  @include fluid(
    font-size,
    (
      s: 15px,
      xl: 18px,
    )
  );

  display: inline-flex;
  justify-content: flex-start;
  color: $c-black;
  line-height: 1.3;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $c-blue;
  }
}

.footer__newsletter {
  @include mq($until: m) {
    display: none;
  }
}

// PARTNERS
.footer__partners {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.footer__partners__link {
  margin-right: 2rem;
}

.partners-wallonie {
  width: 3.3rem;

  @include mq(m) {
    width: 4.5rem;
  }
}

.partners-smartregion {
  width: 16.3rem;

  @include mq(m) {
    width: 22.5rem;
  }
}

// LEGAL
.footer__legal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $spacing / 2;
  font-size: 1.1rem;
  line-height: 2rem;

  > * {
    opacity: 0.6;
  }

  p {
    margin: 0;
  }
}

.footer__epic-logo {
  color: inherit;
  text-decoration: none;
  opacity: 0.4;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
