
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































































.gallery {
  @include mq($until: m) {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.gallery-outer {
  position: relative;
}

.gallery__list {
  @include aspect-ratio(36, 24);

  @include mq(m) {
    @include aspect-ratio(16, 9);
  }
}

.gallery__item {
  @include get-all-space;

  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }
}

.gallery__item__picture {
  @include image-fit;

  @include mq(m) {
    border-radius: 4rem;
  }
}

.gallery-controls {
  @include mq($until: m) {
    display: none;
  }
}

[class*='gallery-controls__item--'] {
  position: absolute;
  z-index: 9;
  bottom: -6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;
  background-color: $c-white;
  border: 2px solid $c-yellow;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $c-yellow;
    border-color: $c-yellow;
  }

  &[class*='--prev'] {
    right: 6rem;
  }

  &[class*='--next'] {
    right: 1rem;
  }

  &.is-disabled {
    border-color: $c-white;
    cursor: default;

    &:hover {
      background-color: $c-white;
      border-color: $c-white;
    }
  }
}

.gallery-controls__item__icon {
  display: block;
  width: 1.4rem;
  fill: $c-black;

  .is-disabled & {
    fill: $c-gray-light;
  }

  [class*='gallery-controls__item--'][class*='--prev'] & {
    transform: rotate(90deg) translateY(2px);
  }

  [class*='gallery-controls__item--'][class*='--next'] & {
    transform: rotate(-90deg) translateY(2px);
  }
}

.gallery-nav,
.wysiwyg ol.gallery-nav {
  @extend %list-nostyle;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  li {
    padding-bottom: 2rem;

    &::before {
      display: none;
    }
  }

  @include mq(m) {
    display: none;
  }
}

.gallery-nav__item {
  button {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.4rem;
    padding: 0;
    border: 0;
    border-radius: 999em;

    span {
      @extend %visually-hidden;
    }
  }

  &.is-active {
    button {
      background-color: $c-yellow;
    }
  }
}

.gallery-pagination {
  position: absolute;
  right: col(1, 12);
  bottom: 1.2rem;
  display: block;
  color: $c-gray;
  font-size: 1.5rem;
  line-height: 1.6;

  &__current {
    margin-right: 0.3rem;
    color: $c-black;
  }

  @include mq(m) {
    right: 12rem;
    bottom: -5.3rem;
  }
}

.gallery__caption {
  @include mq(m) {
    min-height: 8rem;
  }
}

.gallery__caption-inner {
  margin-inline: col(1, 12);
  padding: 0;
  color: $c-gray;
  font-size: 1.5rem;
  line-height: 1.2em;
  text-align: center;

  @include mq(m) {
    margin: 0;
    padding: $spacing * 1.5 12rem $spacing * 1.5 1rem;
    text-align: left;
  }
}
