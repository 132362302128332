
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































































.section {
  @extend %ff-alt;

  display: block;
  width: 100%;
  padding-bottom: $spacing * 0.5;

  @include mq(m) {
    padding-bottom: $spacing;
  }

  &.mobility {
    padding-top: 3rem;
    background-color: $c-white;

    @include mq(m) {
      padding-top: 9rem;
      padding-right: col(1, 12);
      padding-left: col(1, 12);
    }

    @include mq(xl) {
      width: 50%;
      padding-right: col(1, 24);
      padding-left: col(1, 24);
    }

    @include mq(xxxl) {
      width: col(9, 24);
    }
  }

  &.duo {
    padding-top: 0;
    padding-bottom: 0;

    @include mq(xl) {
      width: 50%;
    }

    @include mq(xxxl) {
      width: col(9, 24);
    }
  }

  &.map,
  &.data {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    @include mq(m) {
      padding-right: col(1, 12);
      padding-left: col(1, 12);

      .section__content {
        width: col(3, 10);
        margin-right: col(2, 10);
      }

      .section__list-outer {
        width: col(5, 10);
      }
    }

    @include mq(xl) {
      padding-top: 9rem;
      padding-right: col(1, 9);
      padding-left: col(1, 9);

      .section__content {
        width: col(3, 7);
        margin-right: col(1, 7);
      }

      .section__list-outer {
        width: col(3, 7);
      }
    }

    @include mq(xxxl) {
      height: 50%;
    }
  }

  &.map {
    @include mq(m) {
      background-color: $c-green;
    }
  }

  &.data {
    @include mq(m) {
      background-color: $c-blue;
    }

    @include mq(xl) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &.secondary {
    width: 100%;

    @include mq($until: m) {
      margin-top: $spacing * 4;
    }

    @include mq(m) {
      padding-right: col(1, 12);
      padding-left: col(1, 12);
      background-color: $c-yellow;
    }

    @include mq(xl) {
      padding-right: col(1, 24);
      padding-left: col(1, 24);
    }

    @include mq(xxxl) {
      width: col(6, 24);
      padding-top: 13.5rem;
    }
  }

  @include mq($until: m) {
    padding-bottom: $spacing;
  }

  @include mq(m) {
    padding-top: $spacing * 2;
    padding-bottom: 5rem;
  }
}

.section__title {
  @include fluid(
    font-size,
    (
      s: 36px,
      xl: 30px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 42px,
      xl: 45px,
    )
  );

  display: flex;
  align-items: center;
  width: 100%;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1rem;

    .section__back & {
      margin-left: 0;
    }

    @include mq($until: m) {
      // margin-top: 0.7rem;
    }
  }
}

.section__text {
  color: $c-white;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.section__list-outer {
  @include mq($until: m) {
    position: absolute;
    z-index: 1;
    top: 3.5rem;
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: col(2, 24);
    padding-left: col(2, 24);
    background-color: $c-white;
  }
}

.section__list {
  @extend %list-nostyle;

  li {
    margin-bottom: $spacing * 1.5;

    @include mq(m) {
      margin-bottom: $spacing;
    }
  }

  .mobility & {
    margin-bottom: 1rem;

    @include mq(m) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3.5rem;
      margin-bottom: $spacing;

      > li {
        width: 50%;
      }
    }

    @include mq(xl) {
      display: block;

      > li {
        width: auto;
      }
    }
  }
}

.section__link {
  display: block;
  color: $c-black;
  font-size: 2rem;
  line-height: 2.5rem;
  text-decoration: none;

  @include mq(m) {
    &:hover {
      color: $c-white;
    }
  }
}

.section__back {
  display: flex;
  align-items: center;

  span {
    margin-left: 2rem;
    color: rgba($c-black, 0.3);
    font-size: 1.6rem;
  }
}
