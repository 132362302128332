
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























// Defaults
.action,
[class*='action--'] {
  color: $c-black;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: $c-black;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  &:disabled {
    opacity: 0.25;
  }
}

.action__icon {
  fill: currentColor;
  width: 2rem;
  height: 2rem;

  [class*='action--'][class*='--slider'][class*='--arrowLeft'] &,
  [class*='action--'][class*='--slider'][class*='--arrowRight'] & {
    margin: 0;
  }

  @include mq(m) {
    width: 4rem;
    height: 4rem;
  }
}

.action__label {
  [class*='--no-label'] &,
  [class*='--slider'] & {
    @extend %visually-hidden;
  }
}

// Slider
[class*='action--'][class*='--btn'][class*='--slider'] {
  position: relative;
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  padding: 0;
  background: transparent;
  border: 2px solid $c-white;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background: $c-white;
  }

  &:disabled {
    border-color: transparent;

    &:hover {
      background: transparent;
    }
  }

  .action__icon-wrapper {
    @include center-xy;

    display: flex;
  }

  @include mq(m) {
    width: 8rem;
    height: 8rem;
  }
}
