
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































































$radius: 2rem;
$padding: $spacing * 1.5 $spacing * 1.5;

.dashboard-tile,
[class*='dashboard-tile--'] {
  display: flex;
  flex-direction: column;
  background-color: $c-white;
  border: 1px solid $c-black-10;
  border-radius: $radius;
}

.dashboard-tile__header {
  display: flex;
  align-items: flex-start;
  min-height: 7.2rem;
  padding: $padding;
  background-color: $c-white;
  border-radius: $radius $radius 0 0;
}

.dashboard-tile__header__title {
  margin-right: 1em;
  line-height: 1.3;
}

.dashboard-tile__footer {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: $padding;
}

.dashboard-tile__header,
.dashboard-tile__footer {
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
    padding: 0;
    background: none;
    border: 0;
  }
}

.help {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  padding: 0;
  border: 1px solid $c-yellow !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 50%;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.action,
[class*='action--'] {
  height: 2rem;
  margin-left: auto;
  padding: 0 $spacing * 0.5 !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 8px;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}

[class*='action--'][class*='--bordered'] {
  border: 1px solid $c-yellow;
}

.help,
.action,
[class*='action--'] {
  background-color: $c-white;
  cursor: pointer;
  transition: background-color 0.35s ease-in-out;

  &:hover {
    background-color: $c-yellow;
  }
}

.dashboard-tile__widget {
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0 0 $radius $radius;
}
