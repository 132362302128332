
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































































































































.ping-pong,
[class*='404-pong--'] {
  position: absolute;
  z-index: -1;
  inset: 0;

  canvas {
    width: 100%;
    height: 100%;
  }
}
