
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































.not-found {
  position: relative;
}

.not-found-inner {
  max-width: 90rem;
}

.not-found__title {
  @include fluid(
    font-size,
    (
      xxs: 48px,
      m: 100px,
    )
  );
}

.not-found__text {
  color: $c-blue;
}
