
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.link {
  a {
    @include fluid(
      font-size,
      (
        s: 15px,
        xl: 18px,
      )
    );

    display: inline-flex;
    justify-content: flex-start;
    color: $c-black;
    line-height: 1.5;
    text-decoration: none;

    .link__label-wrapper {
      max-width: 30rem;
    }

    .link__label {
      background-image: linear-gradient($c-black, $c-black),
        linear-gradient($c-blue, $c-blue);
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px, 0 1px;
      transition: background-size 0.3s linear, background-position 0.3s linear;
    }

    .link__icon {
      flex: 0 0 2.4rem;
      margin-right: 1.5rem;
    }

    &:hover {
      color: $c-blue;

      .link__icon {
        fill: $c-blue;
      }

      .link__label {
        background-position: 100% 100%, 0 100%;
        background-size: 0 1px, 100% 1px;
      }
    }
  }
}
