
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































.cta {
  background-color: $c-blue;
  transition: background-color 0.35s ease-in-out;

  &.active {
    background-color: $c-yellow;
  }
}

.cta-inner {
  text-align: center;
}

.cta__title {
  color: $c-white;
}

.cta__btn-wrapper {
  display: inline-flex;
}
