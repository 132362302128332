
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.btn {
  position: relative;
  display: inline-flex;
  overflow: hidden;
  margin: 0;
  padding: 1.2rem 2rem;
  pointer-events: auto;
  cursor: pointer;
  color: $c-black;
  text-decoration: none;
  background-color: $c-yellow;
  border: 2px solid transparent;
  border-radius: 12px;
}

.btn::before,
.btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btn::before {
  content: '';
  top: -110%;
  left: -10%;
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  background: $c-white;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}

.btn:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn::after {
  content: '';
  background: $c-white;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.btn:hover::after {
  transform: translate3d(0, 0, 0);
  transition-delay: 0.4s;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

.btn span {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 15px,
      xl: 18px,
    )
  );

  position: relative;
  z-index: 10;
  display: block;
}

.btn:hover {
  border-color: $c-yellow;

  span {
    animation: move-in 0.3s forwards, move-out 0.3s forwards 0.3s;
  }
}

button.btn {
  padding: 0;
  background: none;
  border: 0;
}

@keyframes move-in {
  to {
    transform: translate3d(0, 105%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes move-out {
  from {
    transform: translate3d(0, -100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
