
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































.btn-outer,
::v-deep .btn-outer {
  text-align: right;
}

code,
::v-deep code {
  color: $c-blue;
}

p,
pre,
::v-deep p,
::v-deep pre {
  line-height: 1.3;
}

/* stylelint-disable-next-line no-descending-specificity */
pre,
::v-deep pre {
  margin-left: 0;
  padding-left: 1em;
  color: $c-gray-dark;
  white-space: pre-wrap;
  background-color: $c-white;
  transition: color 0.2s ease-in, background-color 0.2s ease-in;

  &.copied {
    color: $c-white;
    background-color: $c-gray-lighter;
  }
}
