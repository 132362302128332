// IMPORT GOOGLE FONTS
@import url('https://fonts.googleapis.com/css?family=Martel:300,700&display=swap');

/* stylelint-disable */
@font-face {
  font-family: 'CirculatStd';
  src: url('./../assets/fonts/CircularStd-Medium.otf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darkest;
  font-family: #{$ff-default};
  font-weight: 300;
}
