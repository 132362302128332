h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  @extend %ff-alt;
}

h1,
.h1,
[class*='h1--'] {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 30px,
      l: 36px,
      xl: 60px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 40px,
      l: 40px,
      xl: 70px,
    )
  );
}

h2,
.h2,
[class*='h2--'] {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 24px,
      l: 32px,
      xl: 40px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 32px,
      l: 42px,
      xl: 50px,
    )
  );
}

h3,
.h3,
[class*='h3--'] {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 20px,
      l: 24px,
      xl: 30px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 30px,
      l: 32px,
      xl: 45px,
    )
  );
}

h4,
.h4 {
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 15px,
      l: 18px,
      xl: 20px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 28px,
      l: 24px,
      xl: 30px,
    )
  );
}

.baseline,
[class*='baseline--'],
.lead {
  @extend %ff-default;
  @extend %fw-light;
  @include fluid(
    font-size,
    (
      s: 20px,
      l: 30px,
      xl: 30px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 30px,
      l: 42px,
      xl: 55px,
    )
  );
}

body {
  @extend %ff-default;
  @extend %fw-normal;
  @include fluid(
    font-size,
    (
      s: 15px,
      l: 16px,
      xl: 18px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 28px,
      l: 29px,
      xl: 30px,
    )
  );
}
