
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































.burger-menu {
  position: fixed;
  z-index: 5;
  top: 8rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 6rem);
  transform-origin: 0 0;
  background: $c-white;

  &.opened {
    overflow: auto;
  }

  @include mq(m) {
    top: 0;
    height: 100vh;
    background-color: $c-yellow;
  }

  @include mq(xxxl) {
    background: linear-gradient(90deg, $c-white 50%, $c-yellow 50%);
  }
}

.burger-menu__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: col(1, 12);
  padding-left: col(1, 12);

  @include mq(m) {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(xl) {
    flex-flow: wrap;
    flex-direction: row;
  }

  @include mq(xxxl) {
    height: 100%;
  }
}

.burger-menu__section {
  @extend %ff-alt;

  display: block;
  width: 100%;
  padding-bottom: $spacing;

  ::v-deep .form-input__label {
    @extend %ff-default;
  }

  &.duo {
    padding-top: 0;
    padding-bottom: 0;

    @include mq(xl) {
      width: 50%;
    }

    @include mq(xxxl) {
      width: col(9, 24);
    }
  }

  &.secondary {
    width: 100%;

    @include mq($until: m) {
      margin-top: $spacing * 3;

      .burger-menu__newsletter {
        margin-top: $spacing * 4;
      }
    }

    @include mq(m) {
      display: flex;
      padding-right: col(1, 12);
      padding-left: col(1, 12);
      background-color: $c-yellow;

      .burger-menu__section__list {
        width: 50%;
      }

      .burger-menu__newsletter {
        width: 50%;
        max-width: 32rem;

        ::v-deep .newsletter {
          h4 {
            font-size: 1.8rem;
          }
        }
      }
    }

    @include mq(xl) {
      height: 100%;
      padding-right: col(1, 24);
      padding-left: col(1, 24);

      .burger-menu__newsletter {
        max-width: none;
        padding-left: col(1, 18);
      }
    }

    @include mq(xxxl) {
      display: block;
      width: col(6, 24);
      padding-top: 13.5rem;

      .burger-menu__section__list {
        width: 100%;
      }

      .burger-menu__newsletter {
        width: 100%;
        padding-top: 13.5rem;
        padding-left: 0;
      }
    }
  }

  @include mq(m) {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 3;
  }
}

.burger-menu__section__list {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;

  li {
    display: inline-flex;
    margin-bottom: $spacing * 0.5;
  }
}

.burger-menu__section__link {
  @extend %ff-default;
  @include fluid(
    font-size,
    (
      s: 15px,
      xl: 18px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 34px,
      xl: 30px,
    )
  );

  display: block;
  color: $c-black;
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-decoration: none;

  @include mq(m) {
    &:hover {
      color: $c-white;
    }
  }
}

.slide-fade-enter-active {
  transition: all $transition-duration $easing;
}

.slide-fade-leave-active {
  transition: all $transition-duration $easing;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
