
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































.ctas-grid__grid {
  position: relative;

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3rem 0;
  }
}

.ctas-grid-inner {
  overflow: hidden;
}

.ctas-grid__cta {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
  padding: $spacing;
  background-color: $c-white;
  border: 2px solid rgba($c-black, 0.1);
  border-radius: 3rem;

  &:hover {
    &::after {
      bottom: -24rem;
    }

    &.is-highlight {
      &::after {
        right: -12rem;
      }
    }
  }

  @include mq(m) {
    flex-direction: column;
    width: calc(100% / 4 - 3rem);
    min-height: 40rem;
    margin-bottom: 3rem;
    padding: $spacing * 2;
    text-align: center;
    border-radius: 4rem;

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -32rem;
      left: 50%;
      display: block;
      width: 32rem;
      height: 32rem;
      background: $c-yellow;
      border-radius: 999em;
      transform: translateX(-50%);
      transition: 1s cubic-bezier(0, 0.8, 0, 1);
    }

    &.is-highlight {
      flex-direction: row;
      align-items: center;
      width: 100%;
      min-height: 22rem;
      text-align: left;

      &::after {
        right: -32rem;
        bottom: 50%;
        left: auto;
        transform: translateX(0) translateY(50%);
      }
    }
  }
}

.ctas-grid__cta__icon {
  position: relative;
  width: 6rem;
  height: 6rem;
  transition: 1s cubic-bezier(0, 0.8, 0, 1);

  svg {
    @include get-all-space;
  }

  .ctas-grid__cta:hover & {
    @include mq(m) {
      width: 8rem;
      height: 8rem;
      margin-bottom: 2rem;
    }
  }

  @include mq($until: m) {
    margin-right: 2.5rem;
  }

  @include mq(m) {
    width: 14rem;
    height: 14rem;
    margin: 0 auto 1.6rem;

    .is-highlight & {
      width: 12rem;
      height: 12rem;
      margin: 0 4rem 0 0;
    }
  }
}

.is-highlight {
  &:hover {
    .ctas-grid__cta__icon {
      @include mq(m) {
        margin-bottom: 0;
      }
    }
  }
}

.ctas-grid__cta__title {
  @extend %ff-alt;
  @include fluid(
    font-size,
    (
      s: 16px,
      xl: 20px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 28px,
      xl: 25px,
    )
  );
}

.ctas-grid__cta__subtitle {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 18px,
      xl: 24px,
    )
  );

  color: $c-blue;

  @include mq(m) {
    margin-bottom: 1.5rem;

    .is-highlight & {
      margin: 0;
    }
  }
}

.ctas-grid__cta__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.6rem;
  width: 3.6rem;
  height: 3.6rem;
  background-color: $c-yellow;
  border-radius: 4rem;

  @include mq($until: m) {
    margin: auto 0 auto auto;
  }

  @include mq(m) {
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
    margin: auto auto 0;

    .is-highlight & {
      margin: auto 0 auto auto;
    }
  }
}

.ctas-grid__cta__link {
  @include get-all-space;

  z-index: 2;
}

.pattern-top {
  z-index: -1;
  top: -3rem;
  left: -3rem;
}

.pattern-bottom {
  z-index: -1;
  right: -3rem;
  bottom: -3rem;
}
