
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































.pattern {
  display: none;

  @include mq(m) {
    position: absolute;
    display: inline-flex;
    max-height: 12rem;
  }
}
